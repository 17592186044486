import { CollectionReference, collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { buyTicketFn } from './functions';
import { BuyTicketRequest, Collections, Ticket, TicketType } from '@packages/types';
import { firestore } from '@/firebase';

export class FirebaseClient {
  private ticketCollection: CollectionReference<Ticket, Ticket>;
  private ticketTypeCollection: CollectionReference<TicketType, TicketType>;

  constructor() {
    this.ticketTypeCollection = collection(
      firestore,
      Collections.ticketTypes,
    ) as CollectionReference<TicketType, TicketType>;

    this.ticketCollection = collection(firestore, Collections.tickets) as CollectionReference<
      Ticket,
      Ticket
    >;
  }

  async getTicketTypes(): Promise<TicketType[]> {
    const ticketTypeDocs = await getDocs(query(this.ticketTypeCollection));

    return ticketTypeDocs.docs.map((doc) => doc.data());
  }

  async getTicket(id: string) {
    try {
      const d = doc(this.ticketCollection, id);
      const result = await getDoc(d);
      return result.data() ?? null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async buyTicket(data: BuyTicketRequest) {
    return buyTicketFn({
      name: data.name,
      email: data.email,
      invitedBy: data.invitedBy,
      ticketType: data.ticketType,
    });
  }
}
