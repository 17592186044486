import { queryOptions } from '@tanstack/react-query';
import { FirebaseClient } from './firebaseClient';

const client = new FirebaseClient();

export const QUERY_KEYS = {
  TICKETS: 'tickets',
};

export const ticketTypeQuery = () => {
  return queryOptions({
    queryKey: [QUERY_KEYS.TICKETS, 'ticketTypes'],
    queryFn: async () => {
      return client.getTicketTypes();
    },
    staleTime: 1000 * 60,
  });
};

export const getTicketQuery = (id: string) => {
  return queryOptions({
    queryKey: [QUERY_KEYS.TICKETS, id],
    queryFn: async () => {
      return client.getTicket(id);
    },
    staleTime: 1000 * 60,
  });
};
