const faqs = [
  { question: 'When?', answer: '18.05.2024 from 22:00 onwards.' },
  {
    question: 'Where?',
    answer:
      'Secret location at the heart of Berlin. You will get notified via E-Mail shortly before the event starts with an exact location.',
  },
];
export const FaqPage = () => {
  return (
    <div className="flex flex-col gap-4 w-full text-center max-w-[400px]">
      {faqs.map((faq, index) => {
        return (
          <div key={index}>
            <h2 className="font-bold">{faq.question}</h2>
            <p>{faq.answer}</p>
          </div>
        );
      })}
    </div>
  );
};
