import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ErrorPage } from './pages/ErrorPage';
import { Layout } from './Layout';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { routes } from './routes';

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: routes,
  },
]);

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, staleTime: 60 * 1000 } },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
};
