import { useParams } from 'react-router-dom';
import { CheckIcon, ClipboardCopyIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { getTicketQuery } from '@/api/queries';

const paypalUsername = import.meta.env.VITE_PAYPAL_USERNAME;

export const TicketDetails = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const { data: ticket } = useQuery(getTicketQuery(ticketId ?? ''));

  if (!ticket) return null;

  const ClipboardCopy = () => {
    const copy = () => {
      navigator.clipboard.writeText(ticket.ticketNumber);
      alert('Ticketnumber copied to clipboard!');
    };
    return (
      <button type="button" className="ml-2" onClick={() => copy()}>
        <ClipboardCopyIcon className="w-4 h-4" />
      </button>
    );
  };

  return (
    <div>
      <h1 className="font-bold text-lg text-white">Ticket Status</h1>

      {!ticket.paidAt ? (
        <>
          <h2 className="mt-2 font-bold">Payment pending</h2>
          <span className="text-destructive font-bold">No payment received / processed</span>
          <p className="bg-foreground/10 p-4 border rounded mb-4 mt-4">
            Please send the money via PayPal{' '}
            <a
              className="font-bold text-foreground"
              target="_blank"
              href={`https://paypal.me/${paypalUsername}/${ticket.price}`}
              rel="noreferrer">
              https://paypal.me/{paypalUsername}
            </a>
            <br />
            or via bank transfer
            <br />
            <span className="font-bold">
              NAME: Niklas Deistler <br /> IBAN: DE53 1001 1001 2779 1768 03
            </span>
            <br />
            <br />
            Message: <span className="text-foreground">{ticket.ticketNumber}</span>{' '}
            <ClipboardCopy />
          </p>
        </>
      ) : null}

      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        {ticket.paidAt ? (
          <>
            <dt>Payment:</dt>
            <dd>
              <div className="flex text-success items-center">
                <CheckIcon className="mr-2" />
                Payment received / processed on{' '}
                {Intl.DateTimeFormat('de-DE').format(new Date(ticket.paidAt))}
              </div>
            </dd>
          </>
        ) : null}
        <dt>Ticket number:</dt>
        <dd>
          {ticket.ticketNumber} <ClipboardCopy />
        </dd>
        <dt>Name:</dt>
        <dd>{ticket.name}</dd>
        <dt>Email:</dt>
        <dd>{ticket.email}</dd>
        <dt>Ticket price:</dt>
        <dd>{ticket.price} EUR</dd>
      </dl>
    </div>
  );
};
