export const ImprintPage = () => {
  return (
    <>
      <h1>Impressum</h1>
      <h2 className="mt-2 text-foreground/80">Verantwortlich für Inhalt</h2>
      <p>Paul Schraven</p>
      <h2 className="mt-2 text-foreground/80">Kontakt</h2>
      E-Mail: festival@familyaffairs.info <br />
      Website: familyaffairs.info <br />
    </>
  );
};
