import { Button } from '@packages/ui';
import { Ticket } from '@packages/types';
import { Link, useLocation } from 'react-router-dom';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';

const paypalUsername = import.meta.env.VITE_PAYPAL_USERNAME;

export const SuccessPage = () => {
  const location = useLocation();
  const state = location.state as { ticket: Ticket } | undefined;

  if (!state?.ticket) {
    return (
      <div>
        Thank you for registering for a ticket. You will receive an email shortly with payment
        information.
      </div>
    );
  }

  const ClipboardCopy = () => {
    const copy = () => {
      navigator.clipboard.writeText(state.ticket.ticketNumber);
      alert('Ticketnumber copied to clipboard!');
    };
    return (
      <button type="button" className="ml-2" onClick={() => copy()}>
        <ClipboardCopyIcon className="w-4 h-4" />
      </button>
    );
  };

  return (
    <div>
      <h1 className="font-bold text-lg">Thank you for registering for a ticket.</h1>
      <p className="text-foreground/90">
        You will shortly receive an email with an order confirmation. Please pay the ticket price in
        the next 24 hours or otherwise your registration might get canceled. Please take a look in
        your spam folder for emails from festival@familyaffairs.info
      </p>
      <dl className="mt-4 [&>dd]:mb-2 [&>dt]:text-sm [&>dt]:text-foreground/80">
        <dt>Ticket number:</dt>
        <dd>
          {state.ticket.ticketNumber} <ClipboardCopy />
        </dd>
        <dt>Name:</dt>
        <dd>{state.ticket.name}</dd>
        <dt>Email:</dt>
        <dd>{state.ticket.email}</dd>
        <dt>Ticket price:</dt>
        <dd>{state.ticket.price} EUR</dd>
        <dt>Payment:</dt>
        <dd>
          Please send the money via PayPal to{' '}
          <a
            className="font-bold"
            target="_blank"
            href={`https://paypal.me/${paypalUsername}/${state.ticket.price}`}
            rel="noreferrer">
            https://paypal.me/{paypalUsername}
          </a>
          <br />
          or via bank transfer
          <br />
          <span className="font-bold">
            NAME: Niklas Deistler <br /> IBAN: DE53 1001 1001 2779 1768 03
          </span>
          <br />
        </dd>
      </dl>
      <br />
      <p className="bg-foreground/10 p-4 border rounded mb-4">
        Message for money transfer: <span className="font-bold">{state.ticket.ticketNumber}</span>{' '}
        <ClipboardCopy />
      </p>
      <br />
      <Link to="/" className="block w-full text-center">
        <Button className="px-4 py-4 rounded text-white bg-green-600 hover:bg-green-500">
          Buy another ticket
        </Button>
      </Link>
    </div>
  );
};
