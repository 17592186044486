import { Link, Outlet } from 'react-router-dom';
import background from '@/assets/bg.jpeg';
import video from '@/assets/day.mp4';
import logo from '@/assets/logo.png';

export const Layout = () => {
  return (
    <div
      className="h-screen w-screen overflow-hidden relative"
      style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <video
        autoPlay
        muted
        playsInline
        loop
        className="absolute w-full h-screen object-cover pointer-events-none">
        <source src={video} type="video/mp4" />
      </video>
      <div className="h-screen w-screen bg-background absolute opacity-20"></div>
      <div className="w-screen h-screen z-10 absolute overflow-scroll">
        <div className="h-full flex flex-col">
          <div className="p-8 grow">
            <main className="container text-white max-w-[500px] rounded-xl border-white backdrop-blur-md pb-8 bg-black bg-opacity-20">
              <img src={logo} className="pt-4 w-[300px] ml-auto mr-auto" />
              <Outlet />
            </main>
          </div>
          <footer className="border-t py-2 backdrop-blur bg-white/20 text-green-100 font-bold">
            <div className="container space-x-8 text-center flex justify-center items-center text-xs">
              <Link to="/">Tickets</Link>
              <Link to="/imprint">Imprint</Link>
              <Link to="/privacy">Privacy</Link>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};
